<template>
  <div class="home">
    <div class="page-wrapper">

      <div class="loader" v-if="!pageLoaded">
        <mu-circular-progress :size="36" color="var(--color-red)"></mu-circular-progress>
      </div>

      <div v-if="pageLoaded" style="width: 100%;">

        <OrderStatusDialog :user="user" @return="openSnackBar"/>
        <OrderHistoryDialog :user="user" @return="openSnackBar"/>
        <OrderStatusFilterDialog :open="orderStatusFilterDialogState" :sortData="sort" :filterData="filter" @return="closeOrderStatusFilterDialog"/>

        <p style="text-align: right">
          <mu-button class="btn-print print-none" color="var(--color-orange)" textColor="var(--color-white)" @click="print()" small>
            <mu-icon left value="print"></mu-icon>
            STAMPA
          </mu-button>
        </p>

        <div class="tools">
          <div class="filter-wrap">

            <mu-chip class="filter filter-all" :class="{ 'active': !currOrdersLavStatus }" @click="applyFilter('order-lav-status', '')">
              TUTTI
              <mu-badge :content="(ordersCounter['*'] !== 'undefined') ? String(ordersCounter['*']) : '--'" circle
                        color="var(--color-yellow)"></mu-badge>
            </mu-chip>

            <mu-chip v-for="(status, index) in ordersLavStatus" :key="'order-status'+ index" class="filter"
                     :class="{ 'active': currOrdersLavStatus == status.value }" @click="applyFilter('order-lav-status', status.value)">
              {{ status.label }}
              <mu-badge :content="(ordersCounter[status.value] !== 'undefined') ? String(ordersCounter[status.value]) : '--'" circle
                        color="var(--color-yellow)"></mu-badge>
            </mu-chip>
          </div>

          <mu-button :class="['settings', 'print-none', { 'active': isFilter || isSort }]" @click="openOrderStatusFilterDialog()" flat>
            <mu-icon value="filter_list" :color="(isFilter || isSort) ? 'var(--color-yellow)' : 'var(--color-orange)'"></mu-icon>
          </mu-button>

          <div class="switchmode-wrap print-none">
            <mu-button class="switchmode" :class="{ 'active': linkMode == 0 }" @click="linkMode = 0" large>Stato</mu-button>
            <mu-button class="switchmode" :class="{ 'active': linkMode == 1 }" @click="linkMode = 1" large>Storia</mu-button>
          </div>
        </div>
        <div class="">
          <mu-checkbox v-model="viewCompleted" :label="'Visualizza spediti'" @change="reloadOrders(true)" class="mr-2"></mu-checkbox>
          <mu-checkbox v-model="lastSixMonths" :label="'Ultimi 6 mesi'" @change="reloadOrders(true)"></mu-checkbox>
        </div>

        <div class="orders-table-wrap">
          <table>
            <thead>
            <tr>
              <th style="width: 90px;">STATO</th>
              <th style="width: 80px;">#</th>
              <th style="width: 100px;">CONSEGNA</th>
              <th>
                <ul class="content-big">
                  <li style="width: 100px;">TIPO</li>
                  <li style="width: 50px;">DIAM.</li>
                  <li style="width: 80px;">FINITURA</li>
                  <li style="width: 150px;">BOCCA</li>
                  <li style="width: 150px;">ALIM.</li>
                  <li style="width: 80px;">LATO</li>
                </ul>
                <span class="content-small">DESCRIZIONE</span>
              </th>
              <th style="width: 120px;">RETE</th>
              <th style="width: 130px;">ATTRIBUTI</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(currOrder, index) in ordersData" :key="'order-'+ index" :class="getRowClasses(currOrder)">
              <td style="width: 90px;">{{ (currOrder.acf['order-lav-status']) ? currOrder.acf['order-lav-status'].label : 'Nuovo' }}</td>
              <td style="width: 80px;">{{
                  currOrder.acf['order-oven_id']
                }}{{ (currOrder.acf['order-index'] > 0) ? '-' + currOrder.acf['order-index'] : '' }}
              </td>
              <td style="width: 100px;">{{ currOrder.acf['order-date'] | moment('DD/MM/YY') }}</td>
              <td>
                <ul class="content-big">
                  <li style="width: 100px;">{{ currOrder.acf['order-oven_model'].label }}</li>
                  <li style="width: 50px;">{{ currOrder.acf['order-oven_diameter'].label }}</li>
                  <li style="width: 80px;">{{ currOrder.acf['order-oven_topcoat'].label }}</li>
                  <li style="width: 150px;">{{ currOrder.acf['order-oven_mouth'].label }}</li>
                  <li style="width: 150px;">{{ currOrder.acf['order-oven_fuel'].label }}</li>
                  <li style="width: 80px;">{{ currOrder.acf['order-oven_fuel_side'].label }}</li>
                </ul>
                <ul class="content-small">
                  <li>
                    <span><b>Modello:</b> {{ currOrder.acf['order-oven_model'].label }}</span>
                    <span><b>Diam.:</b> {{ currOrder.acf['order-oven_diameter'].label }}</span>
                  </li>
                  <li>
                    <span><b>Finitura:</b> {{ currOrder.acf['order-oven_topcoat'].label }}</span>
                    <span><b>Bocca:</b> {{ currOrder.acf['order-oven_mouth'].label }}</span>
                  </li>
                  <li>
                    <span><b>Alimentazione:</b> {{ currOrder.acf['order-oven_fuel'].label }}</span>
                    <span><b>Lato:</b> {{ currOrder.acf['order-oven_fuel_side'].label }}</span>
                  </li>
                  <li><span><b>ETL:</b> {{ (currOrder.acf['order-oven_etl']) ? 'SI' : 'NO' }}</span></li>
                </ul>
              </td>
              <td class="td-icons-wrap" style="width: 120px;">
                <span v-if="currOrder.acf['order-oven_rete']"><b>SI</b></span>
                <span v-if="!currOrder.acf['order-oven_rete']"><b>NO</b></span>
                <!--                                    <div class="td-icons" v-if="(currOrder.acf['order-lav-parts'] && currOrder.acf['order-lav-parts'].length) || currOrder.acf['order-lav-edit_oven_props'] || currOrder.acf['order-lav-edit_date'] || currOrder.acf['order-lav-edit_note'] || currOrder.acf['order-lav-edit_transport']">-->
                <!--                                        <mu-tooltip content="Materiale mancante">-->
                <!--                                            <img src="../../assets/images/icons/alert.png" v-if="currOrder.acf['order-lav-parts'] && currOrder.acf['order-lav-parts'].length">-->
                <!--                                        </mu-tooltip>-->
                <!--                                        <mu-tooltip content="Modifica dati tecnici">-->
                <!--                                            <img src="../../assets/images/icons/edit-oven_props.png" v-if="currOrder.acf['order-lav-edit_oven_props']">-->
                <!--                                        </mu-tooltip>-->
                <!--                                        <mu-tooltip content="Modifica data di consegna">-->
                <!--                                            <img src="../../assets/images/icons/edit-date.png" v-if="currOrder.acf['order-lav-edit_date']">-->
                <!--                                        </mu-tooltip>-->
                <!--                                        <mu-tooltip content="Modifica note">-->
                <!--                                            <img src="../../assets/images/icons/edit-note.png" v-if="currOrder.acf['order-lav-edit_note']">-->
                <!--                                        </mu-tooltip>-->
                <!--                                        <mu-tooltip content="Modifica trasporto">-->
                <!--                                            <img src="../../assets/images/icons/edit-transport.png" v-if="currOrder.acf['order-lav-edit_transport']">-->
                <!--                                        </mu-tooltip>-->
                <!--                                    </div>-->
              </td>
              <td class="td-icons-wrap" style="width: 130px;">
                <div class="td-icons">
                  <mu-tooltip :content="currOrder.acf['order-packing'].label">
                    <img :src="getOrderPropIcon(currOrder, 'order-packing')" alt="">
                  </mu-tooltip>
                  <mu-tooltip content="Trasporto a ns. carico">
                    <img src="../../assets/images/icons/trasporto.png" v-if="currOrder.acf['order-transport']">
                  </mu-tooltip>
                </div>
              </td>
              <mu-ripple @click="openOrderStatusDialog(currOrder)" color="var(--color-gray2)" :opacity="0.1"></mu-ripple>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

      <mu-snackbar color="success" :open="pageLoaded && !ordersLoaded" position="bottom-end" style="min-width: 0px;">
        <mu-circular-progress :size="20" color="var(--color-white)" style="margin-right: 10px;"></mu-circular-progress>
        AGGIORNO...
      </mu-snackbar>

      <mu-snackbar :color="(typeof snackBar.return === 'boolean') ? (snackBar.return) ? 'success' : 'error' : snackBar.return"
                   :open.sync="snackBar.open">
        <mu-icon left :value="(snackBar.return) ? 'check_circle' : 'warning'"></mu-icon>
        {{ snackBar.message }}
        <mu-button flat slot="action" color="#fff" @click="closeSnackBar()">CHIUDI</mu-button>
      </mu-snackbar>

    </div>
  </div>
</template>

<style scoped>
@import '../../assets/css/views/cruscotto-home.css';

</style>

<script>
/* eslint-disable */

import OrderStatusDialog from '@/components/OrderStatusDialog.vue'
import OrderHistoryDialog from '@/components/OrderHistoryDialog.vue'
import OrderStatusFilterDialog from '@/components/OrderStatusFilterDialog.vue'

const moment = require('moment');

export default {
  name: 'CruscottoHome',
  data () {
    return {
      user: this.$app.user,
      pageLoaded: false,
      ordersLoaded: false,
      reloadInterval: false,
      linkMode: 0,
      ordersCounter: {},
      isSort: false,
      isFilter: false,
      currOrdersLavStatus: '',
      ordersLavStatus: [],
      sort: {
        'sort-field': 'order-date',
        'sort-type': 'ASC'
      },
      filter: {
        'order-oven_model': [],
        'order-oven_diameter': [],
        'order-oven_topcoat': [],
        'order-oven_mouth': [],
        'order-oven_fuel': [],
        'order-oven_fuel_side': [],
        'order-oven_sill': '',
        'order-oven_etl': '',
        'order-date': {
          age: '',
          start: '',
          end: ''
        }
      },
      orderStatusFilterDialogState: false,
      options: {},
      orders: {},
      ordersData: {},
      snackBar: {
        open: false,
        timer: false,
        message: '',
        return: false
      },
      viewCompleted: false,
      lastSixMonths: true,
    }
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'Cruscotto Ordini') {
        this.getOptions();
      } else {
        this.ordersLoaded = true;
      }
    },
    currOrdersLavStatus: function (val, preVal) {
      this.applyFilter('order-lav-status', val);
    }
  },
  beforeDestroy () {
    this.ordersLoaded = true;
  },
  created () {
    this.getOptions();
    this.getCurrentUser();
    this.getOrdersStatus();
    this.getOrders();
  },
  methods: {

    getCurrentUser: function () {
      if (this.$session.exists()) {
        this.$app.user = this.$session.get('user');
      }
    },

    getOptions: function () {
      this.$http.get(this.$app.api.host + '/wp-json/acf/v3/options/ordini_options', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        this.options = response.body.acf;
        this.options['options-lav-priority_days'] = parseInt(this.options['options-lav-priority_days']);
        this.options['options-updating_time'] = parseInt(this.options['options-updating_time']);
      });
    },

    getOrdersStatus () {
      this.$http.get(this.$app.api.host + '/wp-json/wp/v2/ordini/options', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        params: {
          field: 'field_5c828f92a2e4e'
        }
      }).then((result) => {
        let response = result.body;
        this.ordersCounter['*'] = 0;
        Object.keys(response).map((key, index) => {
          this.ordersLavStatus.push({
            value: key,
            label: response[key]
          });
          this.ordersCounter[key] = 0;
        });
      });
    },

    reloadOrders: function (force = false) {
      if (!this.ordersLoaded && !force) {
        return;
      }
      this.ordersLoaded = false;
      this.getOrders();
    },

    getOrders: function (date = false) {
      let parametri = {
            'post_type': 'ordini',
            'posts_per_page': -1,
            // 'no_found_rows' : 'true',
            // 'update_post_term_cache' : 'false',
            // 'update_post_meta_cache' :'false',
            // 'cache_results' : 'false',
            'meta_key': 'order-date',
            'orderby': 'meta_value',
            'order': 'DESC',
            'meta_query': [
              {
                'relation': 'AND',
              },
              {
                'key': 'order-type',
                'value': 'order',
                'compare': '=',
                'type': 'CHAR'
              }
            ]
          },
          opzioni = {
            'fields': [
              'ID'
            ],
            'meta': [
              'order-oven_id',
              'order-index',
              'order-date',
              'order-oven_model',
              'order-oven_diameter',
              'order-oven_topcoat',
              'order-oven_mouth',
              'order-oven_fuel',
              'order-oven_fuel_side',
              'order-oven_sill',
              'order-oven_rete',
              'order-oven_etl',
              'order-packing',
              'order-transport',
              'order-lav-status',
              'order-lav-parts',
              'order-lav-edit_date',
              'order-lav-edit_oven_props',
              'order-lav-edit_note',
              'order-lav-edit_transport'
            ]
          };

      if (this.lastSixMonths) {
        // let today = moment().format('YYYY-MM-DD');
        let begin = moment().subtract(6, 'months').format('YYYY-MM-DD');

        parametri.meta_query.push({
          'key': 'order-date',
          'value': begin,
          'compare': '>=',
          'type': 'DATE'
        });
      }

      if (!this.viewCompleted) {
        parametri.meta_query.push({
          'key': 'order-lav-status',
          'value': 'lav-status-5',
          'compare': 'NOT LIKE'
        });
      }

      if (!this.ordersLoaded) {
        this.$http.post(this.$app.api.host + '/wp-json/wp/v2/query', {
          // headers: headers,
          'data': {
            'parametri': parametri,
            'opzioni': opzioni,
            'chiave': 'cruscotto'
          }

        }).then((response) => {
          this.ordersLoaded = true;
          this.pageLoaded = true;
          response.body.filter((order) => {
            if (!order.acf['order-lav-status']) {
              order.acf['order-lav-status'] = {
                value: 'lav-status-0',
                label: 'Nuovo',
              };
            }
          });

          this.ordersData = this.orders = response.body;
          this.applyFilter('order-lav-status', this.currOrdersLavStatus);
        });
      }
    },

    getOrderPropIcon: function (order, prop) {
      let images = require.context('../../assets/images/icons/', false, /\.png$/), image = '';
      if (typeof order.acf[prop] == 'object') {
        image = order.acf[prop].value;
      } else {
        image = prop;
      }

      return images('./' + image + ".png");
    },

    isPriorityOrder: function (order) {
      return order.acf['order-lav-status'].value !== this.ordersLavStatus[this.ordersLavStatus.length - 1].value &&
          order.acf['order-lav-status'].value !== 'lav-status-4' &&
          moment(order.acf['order-date']).diff(moment(), 'days') <= this.options['options-lav-priority_days'];
    },

    applyFilter: function (prop, value) {
      if (prop === 'order-lav-status') {
        this.currOrdersLavStatus = value;
      } else if (prop === '*') {
        this.filter = value;
      } else {
        this.filter[prop] = value;
      }

      this.ordersCounter['*'] = 0;
      Object.keys(this.ordersCounter).forEach((key) => {
        this.ordersCounter[key] = 0
      });

      console.log(this.filter);

      let startDate = (this.filter['order-date'].start) ? moment(this.filter['order-date'].start, 'DD/MM/YYYY') : false;
      let endDate = (this.filter['order-date'].end) ? moment(this.filter['order-date'].end, 'DD/MM/YYYY') : false;
      let ovenSill = this.filter['order-oven_sill'] === 'true';
      let ovenEtl = this.filter['order-oven_etl'] === 'true';

      this.ordersData = this.orders.filter((order) => {
        let checkLavStatus = !this.currOrdersLavStatus || order.acf['order-lav-status'].value === this.currOrdersLavStatus;
        let checkOvenDiameter = !this.filter['order-oven_diameter'].length || this.filter['order-oven_diameter'].includes(order.acf['order-oven_diameter'].value);
        let checkOvenModel = !this.filter['order-oven_model'].length || this.filter['order-oven_model'].includes(order.acf['order-oven_model'].value);
        let checkOvenTopcoat = !this.filter['order-oven_topcoat'].length || this.filter['order-oven_topcoat'].includes(order.acf['order-oven_topcoat'].value);
        let checkOvenMouth = !this.filter['order-oven_mouth'].length || this.filter['order-oven_mouth'].includes(order.acf['order-oven_mouth'].value);
        let checkOvenFuel = !this.filter['order-oven_fuel'].length || this.filter['order-oven_fuel'].includes(order.acf['order-oven_fuel'].value);
        let checkOvenFuelSide = !this.filter['order-oven_fuel_side'].length || this.filter['order-oven_fuel_side'].includes(order.acf['order-oven_fuel_side'].value);
        let checkOvenSill = !this.filter['order-oven_sill'] || (ovenSill === order.acf['order-oven_sill']);
        let checkOvenEtl = !this.filter['order-oven_etl'] || (ovenEtl === order.acf['order-oven_etl']);

        let dateBetween = true, orderDate = moment(order.acf['order-date']);
        if (this.filter['order-date'].age) {
          dateBetween = (!endDate) ? orderDate.isSameOrAfter(startDate.format('YYYY-MM-DD')) : orderDate.isBetween(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }

        if (checkOvenModel && checkOvenDiameter && checkOvenTopcoat && checkOvenMouth && checkOvenFuel && checkOvenFuelSide && checkOvenSill && checkOvenEtl && dateBetween) {
          this.ordersCounter['*'] = this.ordersCounter['*'] + 1;
          this.ordersCounter[order.acf['order-lav-status'].value] = this.ordersCounter[order.acf['order-lav-status'].value] + 1;
        }

        return checkLavStatus && checkOvenModel && checkOvenDiameter && checkOvenTopcoat && checkOvenMouth && checkOvenFuel && checkOvenFuelSide && checkOvenSill && checkOvenEtl && dateBetween;
      });

      console.log(this.sort['sort-field']);
      this.ordersData = this.ordersData.sort((a, b) => {
        let a_date = '', b_date = '';

        if (this.sort['sort-field'] === 'order-date') {
          a_date = parseInt(moment(a.acf['order-date']).format('YYYYMMDD'));
          b_date = parseInt(moment(b.acf['order-date']).format('YYYYMMDD'));
          // a_date = parseInt(moment(a.acf['order-date']).format('YYYYMMDD') + '' + a.acf['order-oven_id'] + '' + a.acf['order-index']);
          // b_date = parseInt(moment(b.acf['order-date']).format('YYYYMMDD') + '' + b.acf['order-oven_id'] + '' + a.acf['order-index']);
        } else if (this.sort['sort-field'] === 'post_date_gmt') {
          a_date = parseInt(moment(a['post_date_gmt']).format('YYYYMMDD'));
          b_date = parseInt(moment(b['post_date_gmt']).format('YYYYMMDD'));
          // a_date = parseInt(moment(a['post_date_gmt']).format('YYYYMMDD') + '' + a.acf['order-oven_id'] + '' + a.acf['order-index']);
          // b_date = parseInt(moment(b['post_date_gmt']).format('YYYYMMDD') + '' + b.acf['order-oven_id'] + '' + a.acf['order-index']);
        }

        //data decrescente
        // return b_date - a_date
        //data crescente
        return a_date - b_date
      });

      if (this.sort['sort-type'] === 'DESC' || this.currOrdersLavStatus === 'lav-status-5') {
        this.ordersData = this.ordersData.reverse();
      }
    },

    countOrders: function (status) {
      let count = 0;
      return String(count);
    },

    openOrderStatusFilterDialog: function () {
      this.ordersLoaded = true;
      this.orderStatusFilterDialogState = true;
    },

    closeOrderStatusFilterDialog: function (data) {
      // data.filterData['order-oven_sill'] = (data.filterData['order-oven_sill'] === 'true');
      // data.filterData['order-oven_etl'] = (data.filterData['order-oven_etl'] === 'true');

      this.isFilter = !!(data.filterData['order-oven_model'].length || data.filterData['order-oven_diameter'].length || data.filterData['order-oven_topcoat'].length || data.filterData['order-oven_mouth'].length || data.filterData['order-oven_fuel'].length || data.filterData['order-oven_fuel_side'].length || data.filterData['order-oven_sill'] !== '' || data.filterData['order-oven_etl'] !== '' || data.filterData['order-date'].age || data.filterData['order-date'].start || data.filterData['order-date'].end);
      this.sort = data.sortData;
      this.isSort = data.sortData['sort-field'] !== 'order-date' || data.sortData['sort-type'] !== 'ASC';
      this.applyFilter('*', data.filterData);
      this.getOptions();
      this.orderStatusFilterDialogState = false;
    },

    openOrderStatusDialog: function (order) {
      if (this.linkMode === 0) {
        this.$router.push({
          name: 'Stato Ordine',
          params: {
            orderData: order.ID
          }
        });
      } else if (this.linkMode === 1) {
        this.$router.push({
          name: 'Storia Ordine',
          params: {
            orderData: order.ID
          }
        });
      }
    },

    openSnackBar: function (response) {
      this.snackBar.open = true;
      if (!response.permanent) {
        if (this.snackBar.timer) clearTimeout(this.snackBar.timer);
        this.snackBar.timer = setTimeout(() => {
          this.snackBar.open = false;
        }, 3000);
      }

      this.snackBar.message = response.message;
      this.snackBar.return = response.status;
      if (response.reloadOrder) {
        this.ordersLoaded = false;
        this.getOrders();
      }
    },

    closeSnackBar: function () {
      if (this.snackBar.timer) {
        clearTimeout(this.snackBar.timer);
      }
      this.snackBar.open = false;
    },

    print: function () {
      window.print()
    },

    getRowClasses: function (currOrder) {
      let mosaicoArr = [
        'oven_topcoat-2', //Cupola Rasata
        'oven_topcoat-25', //Cupola Rasata Verniciata
        'oven_topcoat-12', //Cupola con Mosaico
        'oven_topcoat-32', //Cupola con Mosaico con Carenatura
        'oven_topcoat-13', //Cupola con Palladiana
        'oven_topcoat-33', //Cupola con Palladiana con Carenatura
        'oven_topcoat-4', //Mosaico
        'oven_topcoat-34', //Mosaico con Carenatura
        'oven_topcoat-5', //Palladiana
        'oven_topcoat-35', //Palladiana con Carenatura
      ];
      let priority = !currOrder.acf['order-transport'] && this.isPriorityOrder(currOrder);
      let mosaico = mosaicoArr.includes(currOrder.acf['order-oven_topcoat'].value);

      return {
        'priority': priority,
        'mosaico': mosaico && !priority
      }
    }

  },
  components: {
    OrderStatusDialog,
    OrderHistoryDialog,
    OrderStatusFilterDialog
  }
}

</script>
